










































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {Jaiza} from '../jaiza.types';
import JaizaService from '../jaiza.service';
import moment from 'moment';

@Component({
  components: {AppLoader, LoadingOverlay}
})
export default class ViewTableJaizas extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  tableData: Jaiza[] = [];

  private personId: string = "";
  private dateOfBirth: string = "";

  headers = [
    {text: 'Jahr', value: 'year'},
    {text: 'Erstellt', value: 'createDate'},
    {text: 'Abgeschlossen', value: 'dateFinished'},
    {text: 'Status', value: 'status'}
  ];

  private loadingJaizas: boolean = false;

  created() {
    if (this.$route.params.personId && this.$route.params.dateOfBirth) {
      this.personId = this.$route.params.personId;
      this.dateOfBirth = this.$route.params.dateOfBirth;
      this.loadJaizas();
    } else if (this.$route.query.personId && this.$route.query.dateOfBirth) {
      //@ts-ignore
      this.personId = this.$route.query.personId;
      //@ts-ignore
      this.dateOfBirth = this.$route.query.dateOfBirth;
      this.loadJaizas();
    } else {
      this.$router.push({path: '/'});
    }
  }

  loadJaizas() {
    this.loadingJaizas = true;

    //@ts-ignore
    JaizaService.getJaizas(this.personId).then((response: any) => {
      //We only want the latest delete request to be seen
      this.loadingJaizas = false;

      this.tableData = response.data;

    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingJaizas = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingJaizas = false;
      }, 5000);
    });
  }

  customRowClass(item: any) {
    if (item.status === 'NEW_TAJNEED_REQUEST') {
      return 'blue-bg';
    }
    if (item.status === 'SENT') {
      return 'green-bg';
    }
    if (item.status === 'DELETE_REQUESTED' || item.status === 'SHIFT_REQUESTED') {
      return 'yellow-bg';
    }
    if (item.status === 'NEW_TAJNEED_DECLINED' || item.status === 'DELETE_TAJNEED_DECLINED') {
      return 'red-bg';
    } else {
      return 'normal';
    }
  }

  handleEdit(selectedEntry: any) {
    //@ts-ignore
    this.$router.push({
      name: 'ViewJaiza',
      params: {
        personId: this.personId,
        year: selectedEntry.year,
        dateOfBirth: this.dateOfBirth
      }
    });
  }


  getTextForStatus(status: string): string {
    switch (status) {
      case "CREATED" :
        return "ERSTELLT"
      case "SAVED" :
        return "ZWISCHENGESPEICHERT"
      case "SENT_BY_WAQIFIN" :
        return "GESENDET VON WAQIFIN"
      case "SAVED_BY_JUDGE" :
        return "GESPEICHERT VON JUDGE"
      case "SENT_BY_JUDGE" :
        return "GESENDET VON JUDGE"
      case "SENT_BY_LOCAL_SECRETARY" :
        return "GESENDET VON JAMAAT/HALQA SEKRETÄR"
      case "SENT_BY_LOCAL_MUAWINA" :
        return "GESENDET VON LOKAL MUAWINA"
      case "SENT_BY_REGIONAL_SECRETARY" :
        return "GESENDET VON LOCAL SEKRETÄR"
      case "SENT_BY_REGIONAL_MUAWINA" :
        return "GESENDET VON REGIONAL MUAWINA"
      case "SAVED_BY_NATIONAL_JAMAAT" :
        return "GESPEICHERT VON NATIONAL SHOBA"
      case "SENT_BY_NATIONAL_JAMAAT" :
        return "GESENDET VON NATIONAL SHOBA"
      case "SAVED_BY_NATIONAL_LAJNA" :
        return "GESPEICHERT VON NATIONAL SHOBA"
      case "SENT_BY_NATIONAL_LAJNA" :
        return "GESENDET VON NATIONAL SHOBA"
      default:
        return "";
    }
  }

}
