import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import {router} from './router';
import {store} from './store';
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification';
import './core/plugins/signature-pad';
import moment from 'moment';
import {RouteConfig} from "vue-router";
import {getAuthenticatedUser, refreshToken} from "./utils/utils";
import {Useraccount} from "@/models/useraccount.model";
import {UseraccountService} from "@/modules/useraccount/useraccount.service";


Vue.config.productionTip = false;

Vue.use(Notifications);

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY ')
  }
});

async function init() {
  const routes: RouteConfig[] = [];
  await getAuthenticatedUser().then((authenticated: boolean) => {
      if (authenticated) {
        let user: Useraccount = store.getters['useraccount/getUseraccount'];
        if (user.isNormalUser) {
          UseraccountService.getOwnMetaData(user.getUsername)
            .then((response: any) => {
              store.commit("accountmetadata/SET_OWN_METADATA", response.data);
            })
            .catch((error: any) => {
            }).finally(() => {
            setTimeout(() => {
            }, 5000);
          });
        }

        if (user.getRoles.indexOf("ROLE_NORMAL_MEMBER") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/dashboard/personen',
            },
          )
        } else if (user.getRoles.indexOf("ROLE_NATIONAL_SHOBA_JAMAAT") >= 0 || user.getRoles.indexOf("ROLE_NAIB_AMIR") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/alle',
              meta: {
                id: 'all'
              }
            }
          );
        }
        else if (user.getRoles.indexOf("ROLE_NATIONAL_SHOBA_LAJNA") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/waqifat',
              meta: {
                id: 'all'
              }
            }
          );
        }
        else if (user.getRoles.indexOf("ROLE_LOCAL_SECRETARY_JAMAAT") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/waqifin',
              meta: {
                id: 'waqifin'
              }
            }
          );
        }
        else if (user.getRoles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/waqifat',
              meta: {
                id: 'waqifat'
              }
            }
          );
        }
        else if (user.getRoles.indexOf("ROLE_REGIONAL_SECRETARY_JAMAAT") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/waqifin',
              meta: {
                id: 'waqifin'
              }
            }
          );
        } else if (user.getRoles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0) {
          routes.push(
            {
              path: '/',
              redirect: '/tajneed/tabelle/waqifat',
              meta: {
                id: 'waqifat'
              }
            }
          );
        }
        else {
          store.commit('tajneed/SET_TAJNEED', []);
          routes.push(
            {
              path: '/',
              name: 'login',
            }
          );
        }
        //We have to do this, because main.ts is called after router has been executed:
        router.addRoutes(routes);
      }
    }
  );


  router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login', '/registrieren', '/passwortzuruecksetzen', '/datenschutz', '/accountaktivieren'];

    let authRequired = !publicPages.includes(to.path);
    let authenticated = store.getters['useraccount/getIsAuthenticated'];
    if (authRequired && !authenticated) {
      try {
        const statusCode = await refreshToken();
        if (statusCode !== 200) {
          next("/login");
        }
        else next();
      } catch (error) {
        next("/login");
      }
    } else if ((to.path == '/login' || to.path == ('/accountaktivieren')) && authenticated) {
      next("/");
    } else {
      next();
    }
  });

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
}

init();
