import axios from "axios";
import {performLogout, refreshTokenInternal} from "@/utils/utils";
import {router} from "@/router";

const isHandlerEnabled = (config = {}) => {
  // @ts-ignore
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

// axiosInstance.get('/v2/api-endpoint', { handlerEnabled: false })

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  // timeout: 10000
});

const requestHandler = (request: any) => {
  //if (isHandlerEnabled(request)) {
  // console.log("Request Interceptor", request);
  //}
  if (request.url.toString().indexOf("api/auth/refresh") < 0
    && request.url.toString().indexOf("logout") < 0
    && request.url.toString().indexOf("/api/basedata/jamaatsandmajalis") <0
    && request.url.toString().indexOf("/registration/register") < 0
    && request.url.toString().indexOf("/api/auth/resetpassword") < 0
    && request.url.toString().indexOf("/api/auth/activateaccount") < 0
  ) {
    refreshTokenInternal();
  }
  return request;
};

const errorHandler = (error: any) => {
  //if (isHandlerEnabled(error.config)) {
  // console.log("Error Interceptor", error);
  // }
  if (error.response) {
    if (error.response.status === 401) {
      performLogout();
      router.push({name: 'login'});
    } else {
      // refreshTokenInternal();
    }
  }
  return Promise.reject({...error});
};

const successHandler = (response: any) => {
  /* if (isHandlerEnabled(response.config)) {
    console.log("Response Interceptor", response);
  } */

  /* if (response.config.url.toString().indexOf("api/auth/refresh") < 0 && response.config.url.toString().indexOf("logout") < 0) {
    refreshTokenInternal();
  }*/
  return response;
};

instance.interceptors.request.use(request => requestHandler(request));

instance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default instance;
