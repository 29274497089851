import LoginView from '../../src/modules/_shell/views/LoginView.vue';
import ActivateAccountView from '../../src/modules/_shell/views/ActivateAccountView.vue';

import DataPrivacy from '../../src/modules/_shell/views/DataPrivacy.vue';
import TajneedTable from '../../src/modules/tajneed/views/tajneed/TajneedTable.vue';
import Approvals from '../../src/modules/tajneed/views/approvals/Approvals.vue';
import Archive from '../../src/modules/tajneed/views/archive/Archive.vue';
import ArchiveShiftAusland from '../../src/modules/tajneed/views/archive/ArchiveShiftAusland.vue';
import ArchiveAmooreAmaCase from '../../src/modules/tajneed/views/archive/ArchiveAmooreAmaCase.vue';
import ArchiveExpired from '../../src/modules/tajneed/views/archive/ArchiveExpired.vue';
import ArchiveWaqfenauRefusal from '../../src/modules/tajneed/views/archive/ArchiveWaqfenauRefusal.vue';
import ArchiveJamia from '../../src/modules/tajneed/views/archive/ArchiveJamia.vue';
import TajneedForm from '../../src/modules/tajneed/views/tajneed/TajneedForm.vue';
import ViewDashboardPersons from '../../src/modules/dashboard/views/ViewDashboardPersons.vue';
import ViewDashboardMenu from '../../src/modules/dashboard/views/ViewDashboardMenu.vue';
import Registration from '../../src/modules/registration/views/Registration.vue';
import ViewSettings from '../../src/modules/useraccount/views/ViewSettings.vue';
import ViewAddChild from '../../src/modules/useraccount/views/ViewAddChild.vue';
import ViewResetPassword from '../../src/modules/_shell/views/ViewResetPassword.vue';
import ViewError from '../../src/core/views/ViewError.vue';
import ViewReportIndividual from '../../src/modules/report/views/ViewReportIndividual.vue';
import ViewTableIndividualReports from '../../src/modules/report/views/ViewTableIndividualReports.vue';
import ViewJaiza from '../../src/modules/jaiza/views/ViewJaiza.vue';
import ViewTableJaizas from '../../src/modules/jaiza/views/ViewTableJaizas.vue';
import ViewAccounts from '../modules/administration/accounts/views/ViewAccounts.vue';
import ViewRegistrationsTable from '../../src/modules/registration/views/ViewRegistrationsTable.vue';
import ViewBasedata from '../../src/modules/basedata/views/ViewBasedata.vue';
import ViewLajnaMajalis from '../../src/modules/basedata/views/ViewLajnaMajalis.vue';
import ViewTableIndividualReportsGroup from '../../src/modules/report/views/ViewTableIndividualReportsGroup.vue';
import ViewTableLocalReports from '../../src/modules/report/views/ViewTableLocalReports.vue';
import ViewLocalReport from '../../src/modules/report/views/ViewLocalReport.vue';
import ViewMissionaryReport from '../../src/modules/report/views/ViewMissionaryReport.vue';
import ViewTableMissionaryReports from '../../src/modules/report/views/ViewTableMissionaryReports.vue';
import ViewDiary from '../../src/modules/diary/views/ViewDiary.vue';
import ViewIndividualDevelopment from '../../src/modules/report/views/ViewIndividualDevelopment.vue';
import ViewJaizasGroup from '../../src/modules/jaiza/views/ViewJaizasGroup.vue';
import ViewJudges from '../../src/modules/jaiza/views/ViewJudges.vue';
import ViewJudgesRegion from '../../src/modules/jaiza/views/ViewJudgesRegion.vue';
import ViewAnalysisCoverage from '../../src/modules/analysis/views/ViewAnalysisCoverage.vue';
import ViewTableLocalReportsGroup from '../../src/modules/report/views/ViewTableLocalReportsGroup.vue';
import ViewTableRegionalReports from '../../src/modules/report/views/ViewTableRegionalReports.vue';
import ViewRegionalReport from '../../src/modules/report/views/ViewRegionalReport.vue';
import ViewAnalysisJaiza from '../../src/modules/analysis/views/ViewAnalysisJaiza.vue';
import ViewTableNationalReports from '../../src/modules/report/views/ViewTableNationalReports.vue';
import ViewNationalReport from '../../src/modules/report/views/ViewNationalReport.vue';
import ViewTableRegionalReportsGroup from '../../src/modules/report/views/ViewTableRegionalReportsGroup.vue';
import ViewWriteEmail from '../../src/modules/email/views/ViewWriteEmail.vue';
import ViewConfiguration from '../../src/modules/administration/configuration/views/ViewConfiguration.vue';
import ViewReportToHuzoor from '../../src/modules/report/views/ViewReportToHuzoor.vue';
import ViewMissionariesList from '../../src/modules/parents-councelling/views/ViewMissionariesList.vue';
import ViewParentsCounsellingCases from '../../src/modules/parents-councelling/views/ViewParentsCounsellingCases.vue';
import FirstConfirmationMissingTableView from '../../src/modules/tajneed/views/tajneed/FirstConfirmationMissingTableView.vue';
import SecondConfirmationMissingTableView from '../../src/modules/tajneed/views/tajneed/SecondConfirmationMissingTableView.vue';
import ViewDownloadsNisab from '../../src/modules/downloads/views/ViewDownloadsNisab.vue';
import ViewDownloadsUrdu from '../../src/modules/downloads/views/ViewDownloadsUrdu.vue';
import ViewDownloads from '../../src/modules/downloads/views/ViewDownloads.vue';

import ViewDocuments from '../../src/modules/documents/views/ViewDocuments.vue';
import ViewTableExamsIndividual from '../../src/modules/exam/views/ViewTableExamsIndividual.vue';
import ViewExam from '../../src/modules/exam/views/ViewExam.vue';
import ViewExamsGroup from '../../src/modules/exam/views/ViewExamsGroup.vue';

import {RouteConfig} from "vue-router";

const routes: RouteConfig[] = [
  {
    path: '*',
    name: 'error',
    component: ViewError,
    meta: {
      layoutPlain: true,
    }
  },
  {
    path: '/datenschutz',
    name: 'dataprivacy',
    component: DataPrivacy,
    meta: {
      layoutPlain: true,
    },
  },
  {
    path: '/dashboard/personen',
    name: 'ViewDashboardPersons',
    component: ViewDashboardPersons,
  },
  {
    path: '/dashboard/menue/:tajneedId',
    name: 'ViewDashboardMenu',
    component: ViewDashboardMenu,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      layoutPlain: true,
    },
  },
  {
    path: '/accountaktivieren',
    name: 'ActivateAccountView',
    component: ActivateAccountView,
    meta: {
      layoutPlain: true,
    },
  },
  {
    path: '/tajneed/tabelle/alle',
    name: 'TajneedTableAll',
    component: TajneedTable,
    meta: {
      id: 'all'
    }
  },
  {
    path: '/tajneed/tabelle/waqifin',
    name: 'TajneedTableWaqifin',
    component: TajneedTable,
    meta: {
      id: 'waqifin'
    }
  },
  {
    path: '/tajneed/tabelle/waqifat',
    name: 'TajneedTableWaqifat',
    component: TajneedTable,
    meta: {
      id: 'waqifat'
    }
  },
  {
    path: '/tajneed/formular/:tajneedId',
    name: 'TajneedForm',
    component: TajneedForm,
  },
  {
    path: '/account/kindhinzufuegen',
    name: 'ViewAddChild',
    component: ViewAddChild,
  },
  {
    path: '/archiv',
    name: 'archive',
    component: Archive
  },
  {
    path: '/archiv/umzugausland',
    name: 'archiveshiftausland',
    component: ArchiveShiftAusland
  },
  {
    path: '/archive/amooreamafall',
    name: 'archiveamooreamacase',
    component: ArchiveAmooreAmaCase
  },
  {
    path: '/archiv/verstorben',
    name: 'archiveexpired',
    component: ArchiveExpired
  },
  {
    path: '/archiv/waqfenauabgelehnt',
    name: 'archivewaqfenaurefusal',
    component: ArchiveWaqfenauRefusal
  },
  {
    path: '/archiv/jamia',
    name: 'archivejamia',
    component: ArchiveJamia
  },
  {
    path: '/genehmigungen/waqifin',
    name: 'ApprovalsWaqifin',
    component: Approvals,
    meta: {
      id: 'waqifin'
    }
  },
  {
    path: '/genehmigungen/waqifat',
    name: 'ApprovalsWaqifat',
    component: Approvals,
    meta: {
      id: 'waqifat'
    }
  },
  {
    path: '/einstellungen',
    name: 'ViewSettings',
    component: ViewSettings,
  },
  {
    path: '/registrieren',
    name: 'registration',
    component: Registration,
    meta: {
      layoutPlain: true,
    },
  },
  {
    path: '/passwortzuruecksetzen',
    name: 'ViewResetPassword',
    component: ViewResetPassword,
    meta: {
      layoutPlain: true,
    },
  },
  {
    path: '/bericht/formular/individual',
    name: 'ViewReportIndividual',
    component: ViewReportIndividual
  },
  {
    path: '/bericht/tabelle/individual/:personId',
    name: 'ViewTableIndividualReports',
    component: ViewTableIndividualReports
  },
  {
    path: '/bericht/tabelle/gruppe',
    name: 'ViewTableIndividualReportsGroup',
    component: ViewTableIndividualReportsGroup
  },
  {
    path: '/bericht/organisation/tabelle',
    name: 'ViewTableLocalReports',
    component: ViewTableLocalReports
  },
  {
    path: '/bericht/organisation',
    name: 'ViewLocalReport',
    component: ViewLocalReport
  },
  {
    path: '/bericht/murabbi/:id',
    name: 'ViewMissionaryReport',
    component: ViewMissionaryReport
  },
  {
    path: '/bericht/tabelle/murabbi/:jamaat',
    name: 'ViewTableMissionaryReports',
    component: ViewTableMissionaryReports
  },
  {
    path: '/bericht/region',
    name: 'ViewRegionalReport',
    component: ViewRegionalReport,
  },
  {
    path: '/berichte/organisation/region',
    name: 'ViewTableLocalReportsGroup',
    component: ViewTableLocalReportsGroup
  },
  {
    path: '/berichte/region',
    name: 'ViewTableRegionalReports',
    component: ViewTableRegionalReports
  },
  {
    path: '/berichte/regionen',
    name: 'ViewTableRegionalReportsGroup',
    component: ViewTableRegionalReportsGroup
  },
  {
    path: '/berichte/national',
    name: 'ViewTableNationalReports',
    component: ViewTableNationalReports
  },
  {
    path: '/bericht/national',
    name: 'ViewNationalReport',
    component: ViewNationalReport,
  },
  {
    path: '/jaiza/formular/:personId',
    name: 'ViewJaiza',
    component: ViewJaiza
  },
  {
    path: '/jaiza/group',
    name: 'ViewJaizasGroup',
    component: ViewJaizasGroup
  },
  {
    path: '/jaiza/judges',
    name: 'ViewJudges',
    component: ViewJudges
  },
  {
    path: '/jaiza/judges/region',
    name: 'ViewJudgesRegion',
    component: ViewJudgesRegion
  },
  {
    path: '/jaiza/tabelle/individual/:personId',
    name: 'ViewTableJaizas',
    component: ViewTableJaizas
  },
  {
    path: '/dokumente',
    name: 'ViewDocuments',
    component: ViewDocuments
  },
  {
    path: '/tagebuch',
    name: 'ViewDiary',
    component: ViewDiary
  },
  {
    path: '/bericht/entwicklung/individual',
    name: 'ViewIndividualDevelopment',
    component: ViewIndividualDevelopment
  },
  {
    path: '/accounts',
    name: 'ViewAccounts',
    component: ViewAccounts
  },
  {
    path: '/registration/registrationen',
    name: 'ViewRegistrationsTable',
    component: ViewRegistrationsTable
  },
  {
    path: '/stammdaten',
    name: 'ViewBasedata',
    component: ViewBasedata
  },
  {
    path: '/stammdaten/lajnamajalis',
    name: 'ViewLajnaMajalis',
    component: ViewLajnaMajalis
  },
  {
    path: '/analyse/abdeckung',
    name: 'ViewAnalysisCoverage',
    component: ViewAnalysisCoverage
  },
  {
    path: '/analyse/jaiza',
    name: 'ViewAnalysisJaiza',
    component: ViewAnalysisJaiza
  },
  {
    path: '/kommunikation/email/neu',
    name: 'ViewWriteEmail',
    component: ViewWriteEmail
  },
  {
    path: '/administration/konfiguration',
    name: 'ViewConfiguration',
    component: ViewConfiguration
  },
  {
    path: '/bericht/fuer-huzoor',
    name: 'ViewReportToHuzoor',
    component: ViewReportToHuzoor
  },
  {
    path: '/parents-councelling/missionaries',
    name: 'ViewMissionariesList',
    component: ViewMissionariesList
  },
  {
    path: '/parents-councelling/cases',
    name: 'ViewParentsCounsellingCases',
    component: ViewParentsCounsellingCases
  },
  {
    path: '/tajneed/tabelle/erste-zusage-fehlend',
    name: 'FirstConfirmationMissingTableView',
    component: FirstConfirmationMissingTableView
  },
  {
    path: '/tajneed/tabelle/zweite-zusage-fehlend',
    name: 'SecondConfirmationMissingTableView',
    component: SecondConfirmationMissingTableView
  },
  {
    path: '/downloads',
    name: 'ViewDownloads',
    component: ViewDownloads
  },
  {
    path: '/downloads-nisab',
    name: 'ViewDownloadsNisab',
    component: ViewDownloadsNisab
  },
  {
    path: '/downloads-urdu',
    name: 'ViewDownloadsUrdu',
    component: ViewDownloadsUrdu
  },
  {
    path: '/pruefung',
    name: 'ViewExam',
    component: ViewExam
  },
  {
    path: '/pruefung/tabelle/individual/:personId',
    name: 'ViewTableExamsIndividual',
    component: ViewTableExamsIndividual
  },
  {
    path: '/pruefung/gruppe',
    name: 'ViewExamsGroup',
    component: ViewExamsGroup
  },
];



export default routes;
